.footer-container {
    background:#000e2e;
    height: 53vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
}
footer p {
    color: white;
    font-size: 17px;
    width: 500px;
    text-align: center;
    line-height: 26px;
}
.social-media {
    display: flex;
    margin-left: 175px;
}
.social-media a {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f9004d;
    border-radius: 50%;
    margin: 22px 10px;
    color: azure;
    font-size: 20px;
    text-decoration: none;
}
.social-media a:hover {
    transition: 0.4s;
    transform: scale(1.3);
}
@media screen and (max-width:600px) {
    html,body{
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        overflow-x: hidden;
      }
    footer p {
        font-size: 12px;
    }
    .footer-container {
        height: 50vh;
    }
    .social-media {
        margin-left: 185px;
    }
}
@media screen and (min-width:1200px){
    html,body{
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      overflow-x: hidden;
    }
}
@media screen and (min-width:1920px){
    html,body{
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      overflow-x: hidden;
    }
    .footer-container{
        height: 37vh;
    }
}