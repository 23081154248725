.overall{
    height: 100%;
    width: 100%;
    background-image: url(https://media.istockphoto.com/id/692869260/photo/aerial-panorama-over-healthy-green-crops-in-patchwork-pasture-farmland.jpg?s=612x612&w=0&k=20&c=AkR5MZyTBPy4UJbv5kh8u_hTYOEBGBOP6Qw2ITUM06Y=);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
.sign-wrap{
    height: 110vh;
    position: relative;
    top:115px;
    width:100px;
}
.sWrap {
    display: flex;
}
.logForm input {
    width: 500px;
    height: 40px;
    margin: 30px;
    margin-top: 10px;
    padding-left: 5px;
    text-transform: capitalize;
    font-weight: 500;
}
.logForm {
    margin: 25px;
}
.logForm label {
    margin-left: 29px;
    font-size: 15px;
    font-weight: 700;
    color:white;
}
.login {
    margin-left: 30px;
    margin-bottom: 20px;
}
.login-btn {
    position:relative;
    border: 0;
    display: inline-block;
    width: 12rem;
    height: auto;
    cursor: pointer;
    outline: none;
    vertical-align: middle;
    background: transparent;
}
.login-btn:hover .circle {
    width: 200%;
}
.login-btn:hover .arrow {
    background: whitesmoke;
    transform: translateX(1rem);
}
.login-btn:hover .texts {
    color: white;
}
.texts {
    position: absolute;
    inset: 0;
    padding: 0.9rem 0;
    margin-left: 1.85rem;
    line-height: 1.6;
    text-align: center;
    transition: all 0.4s ease-out;
    color:black;
    font-size: 20px;
}
.circle {
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background:rgb(25, 0, 255);
    border-radius: 1.625rem;
    transition: all 0.4s ease-out;
    white-space: nowrap;
}
.icon {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: white;
}
.arrow {
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
    white-space: nowrap;
    transition: all 0.4s ease-out;
}
.arrow::before {
    content: "";
    position: absolute;
    top: -0.3rem;
    height: 0.625rem;
    width: 0.625rem;
    right: 0.0625rem;
    border-top: 0.125rem solid whitesmoke;
    border-right: 0.125rem solid whitesmoke;
    transform: rotate(45deg);    
}
.login p {
    text-transform: capitalize;
}
.login a {
    text-decoration: none;
    color: blue;
}
.login a:hover {
    text-decoration: underline;
}
.detail{
    display: flex;
}
.in{
    display: flex;
    justify-content: center;
    align-items: center; 
    position: relative;
    right:440px;  
    color:white;
    font-weight: 800;
}
.special{
color: red;
  position: relative;
  right: -8px;
}
.social-medias {
    display: flex;
    margin-left: 15px;
}
.social-medias a {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background:black;
    border-radius: 50%;
    margin: 22px 10px;
    color: azure;
    font-size: 20px;
    text-decoration: none;
}
.social-medias a:hover {
    transition: 0.4s;
    transform: scale(1.3);
}
@media screen and (max-width:1200px){
    html,body{
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      overflow-x: hidden;
  }
  .overall{
    background:#000e2e;
  }
.logForm input{
    width: 225px;
    height: 40px;
    margin-top: 10px; 
    z-index: 99;
    margin-left: -15px;
}
.in{
    right: 180px;
}
.image{
    width: 545px;
}
}
@media screen and (min-width:600px){
    html,body{
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        overflow-x: hidden;
      }
      .overall{
        background: linear-gradient(#000e2e,#004f5a)
      }
    .sign-wrap{
        height: 110vh;
        width:100%;
        display: flex;
    }
    .logForm input{
        width: 165px;
        height: 40px;
        margin-top: 10px; 
        z-index: 99;
        margin-left: -15px;
    }
    .fogot{
        position: relative;
        margin-left: 90px;
    }
    .login-btn{
        width: 10rem;
        margin-left: -55px;
    }
    .logForm label{
        margin-left: -15px;
    }
    .in{
        right: 125px;
    }
    .social-medias{
        margin-left: -25px;
    }
    .special{
          left: 10px;
        }
}