.box {
  margin: -20px auto;
  width: 70%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 5px;
}
.card {
  width: 300px;
  height: 305px;
  border: 1px solid black;
  border-radius: 20px;
  overflow: hidden;
  padding: 10px;
  margin: 40px 20px;
  background: #191919;
}
.ar,.us,.bel
{
  font-size: 40px;
  display: block;
  margin: 20px 0px;
  color:cornsilk;
  align-items: center;
  margin-left: 120px;
  margin-bottom: 20px;
}
.card h5 {
  color: whitesmoke;
  font-size: 23px;
  margin-bottom: 15px;
  margin-top: -15px;
  margin-left: 40px;
}
.para p {
  color: #fcfc;
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 25px;
}
.butto,
.button {
  background-color: #f9004d;
  color: white;
  text-decoration: none;
  border: 2px solid transparent;
  font-weight: bold;
  padding: 9px 23px;
  border-radius: 30px;
  transition: 0.5s;
   margin-left: 70px;
}
.butto:hover {
  background-color: transparent;
  border: 2px solid #f9004d;
  cursor: pointer;
}
.images {
  height: 115px;
  width: 280px;
  margin-bottom: 25px;
}
.button:hover {
  background-color: #191919;
  cursor: pointer;
}
.button {
  position: relative;
  left: 450px;
  top: -30px;
}
.giving{
  list-style-type: none;
}
.btns {
  position: relative;
  background-color: rgb(68, 0, 255);
  color: white;
  text-decoration: none;
  border: 2px solid transparent;
  font-weight: bold;
  padding: 10px 25px;
  border-radius: 30px;
  transition: 0.5s;
  border: 1px solid white;
  left: 350px;
  width: 150px;
}
.btns:hover {
  transform: scale(1.1);
}
.outer{
  position: relative;
  background: linear-gradient(#000e2e,#004f5a);
  height: 70vh;
  width: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  top: 150px;
  color: white;
}
.test-outer {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  margin-left: 20px;
}

@media screen and (max-width:600px) {
  html,body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  .card {
    display: none;
  }
  .btns{
    left: 60px;
  }
  .outer{
    height: 70vh;
    width: 360px;
    display: flex;
    margin-left: -20px;
    }
    .outer h1{
      margin-left:20px;
    }
}
@media screen and (max-width:1200px)and (min-width:481px)
{
  html,body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  .card{
    width: 205px;
    height: 265px;
  }
  .ar,.us,.bel
  {
    font-size: 45px;
    margin: 5px 0px;
    margin-left: 60px;
    margin-bottom: 20px;
  }
  .card h5 {
    font-size: 18px;
    margin-bottom: 15px;
    margin-top: -5px;
    margin-left: 20px;
  }
  .para p {
    font-size: 15px;
    line-height: 27px;
    margin-bottom: 25px;
  }
  .joins{
    left:20px;
  }
  .butto,
  .button {
     margin-left: 30px;
  }
  .images {
    height: 90px;
    width: 195px;
    margin-bottom: 30px;
    margin-left: -5px;
  }
  .btns{
    left: 230px;
  }
  .outer{
    height: 76vh;
    width: 1200px;
    margin-left: -20px;
    }
    .outer h1{
      margin-left: -200px;
    }
 }
 @media screen and (min-width:1920px){
  .test-outer {
    height: 90vh;
    
  }
}