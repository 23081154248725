html,body{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.contact-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid black;
  padding-left: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(https://media.istockphoto.com/id/692869260/photo/aerial-panorama-over-healthy-green-crops-in-patchwork-pasture-farmland.jpg?s=612x612&w=0&k=20&c=AkR5MZyTBPy4UJbv5kh8u_hTYOEBGBOP6Qw2ITUM06Y=)
}
.contacts {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.iimage {
  position: relative;
  margin-top: 120px;
  width: 1517px;
  height: 450px;
  right:199px;
}
.contacts h1 {
  font-size: 40px;
  font-weight: normal;
  text-transform: capitalize;
  text-decoration: underline;
  color: white;
}
.name {
  margin-top: -20px;
  font-size: 50px;
  font-weight: 700;
  color: black;
}
h4,
p,
h3 {
  margin-top: -5px;
  color:white;
  font-weight: 600;
}
.home-wrapper {
  height: 233vh;
  width: 100%;
  background-size: cover;
  background-position: center;
}
.auto-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}
.sLides {
  margin-top: 120px;
  height: 400px;
  width: 1050px;
  margin-right: -90px;
  cursor: pointer;
  border-radius: 10px;
}
.lefti {
  position: relative;
  font-size: 3rem;
  color: black;
  left: -1002px;
  cursor: pointer;
  top:50px;
}
.righti {
  position: relative;
  font-size: 3rem;
  color: black;
  right: -35px;
  cursor: pointer;
  top:50px;
}
.about {
  width: 100%;
  padding: 100px opx;
}
.about-text {
  width: 550px;
}
.main {
  width: 1130px;
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.about-text h2 {
  color: whitesmoke;
  text-transform: uppercase;
  font-size: 75px;
  margin-bottom: 20px;
}
.about-text h5 {
  color: rgb(7, 0, 0);
  letter-spacing: 2px;
  font-size: 30px;
  margin-bottom: 25px;
  text-transform: uppercase;
}
.name{
  margin-top: -20px;
  font-size: 50px;
  color: black;
  font-weight: 700;
}
.about-text p {
  color: black;
  letter-spacing: 1px;
  line-height: 28px;
  font-size: 15px;
  margin-bottom: 45px;
}
.profile {
  margin-top: 15px;
  height: 520px;
  width: 430px;
  border-radius: 50px;
  font-size: 40px;
}
.profil {
  position: relative;
  margin-top: 15px;
  height: 520px;
  width: 430px;
  border-radius: 50px;
  font-size: 40px;
  right: 380px;
}
@media screen and (max-width:600px) {
  html,body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  .about {
    display: flex;
    flex-direction: column;
  }
  .iimage {
    height: 260px;
    width: 358px;
  }
  .contact-wrap {
    padding-left: 50px;
  }
  .contacts p,h3,h4,h1{
    margin-left: -40px;
  }
.name {
    margin-top: 10px;
    font-size: 24px;
  }
  .sLides {
    height: 260px;
    width: 360px;
    margin-top: 120px;
    margin-left: -90px;
    margin-bottom: 30px;
    border-radius: 2px;
  }
  .lefti {
    display: none;
  }
  .righti {
    display: none;
  }
  .profile {
    display: none;
  }
  .profil{
    width: 365px;
    height: 530px;
    left: -2px;
    border-radius: 2px;
  }
  .box {
    display: block;
    width: 100%;
    margin-bottom: 100px;
  }
  .button {
    display: none;
  }
  .home-wrapper {
    height: 175vh;
  }
}
@media screen and  (max-width:1200px){
  html,body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
 .box{
width:100%;
 }
 .sLides {
  width: 1200px;
  margin-left: -97px;
}
.lefti{
  display: none;
}
.righti{
  display: none;
}

.contacts p,h3,h4,h1{
  margin-left: -50px;
}
.iimage{
  width: 1205px;
  margin-left: -10px;  
}
.profil{
  width: 390px;
  height: 500px;
  left: -2px;
}
}
@media screen and (min-width:1300px){
  html,body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  .iimage{
    width: 2500px;
  }
}
@media screen and (min-width:1920px){
  .home-wrapper{
  height: 150vh;
  }
  .iimage{
    width: 2700px;
  }
}
