.all {
  background-image: url(https://media.istockphoto.com/id/692869260/photo/aerial-panorama-over-healthy-green-crops-in-patchwork-pasture-farmland.jpg?s=612x612&w=0&k=20&c=AkR5MZyTBPy4UJbv5kh8u_hTYOEBGBOP6Qw2ITUM06Y=);
  height: 110vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.alls{
  background-image: url(https://media.istockphoto.com/id/692869260/photo/aerial-panorama-over-healthy-green-crops-in-patchwork-pasture-farmland.jpg?s=612x612&w=0&k=20&c=AkR5MZyTBPy4UJbv5kh8u_hTYOEBGBOP6Qw2ITUM06Y=);
  height: 97vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.al {
  background-image: url(https://media.istockphoto.com/id/692869260/photo/aerial-panorama-over-healthy-green-crops-in-patchwork-pasture-farmland.jpg?s=612x612&w=0&k=20&c=AkR5MZyTBPy4UJbv5kh8u_hTYOEBGBOP6Qw2ITUM06Y=);
  height: 115vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.als {
  background-image: url(https://media.istockphoto.com/id/692869260/photo/aerial-panorama-over-healthy-green-crops-in-patchwork-pasture-farmland.jpg?s=612x612&w=0&k=20&c=AkR5MZyTBPy4UJbv5kh8u_hTYOEBGBOP6Qw2ITUM06Y=);
  height: 115vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.state-outer,
.forecast-outer,
.objective-outer,
.position-outer,
.join-outer {
  position: relative;
  background: transparent;
  height: 100%;
  width: 100%px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  top: 200px;
}
.line-outer {
  position: relative;
  background: transparent;
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  top: 120px;
}
.history-outer,
.summary-outer {
  position: relative;
  background: transparent;
  height: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  top: 100px;
}
.statements,
.forecasts,
.historys,
.objectives {
  height: 60vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  padding-left: 50px;
}
.statements h1 {
  margin-left: 4px;
  margin-bottom: 50px;
  color: white;
  margin-top: 20px;
  
}
.statements p {
  font-size: 20px;
  font-weight: 900;
  color: white;
  font-size: 23px;
  width: 900px;
}
.forecasts h1 {
  margin-left: 4px;
  margin-bottom: 50px;
  color: white;
  margin-top: 20px;
}
.forecasts p {
  font-size: 20px;
  font-weight: 700;
  color: white;
  font-size: 22px;
  width: 800px;
}
.historys h1 {
  margin-bottom: 50px;
  color: white;
  margin-top: 20px;
}
.historys p {
  font-size: 20px;
  font-weight: 700;
  color: white;
  font-size: 20px;
  width: 800px;
}
.objectives h1 {
  margin-bottom: 50px;
  color: white;
  margin-top: 20px;
}
.objectives p {
  font-size: 20px;
  font-weight: 700;
  color: white;
  font-size: 22px;
  width: 750px;
}
.propositions h1 {
  margin-bottom: 50px;
  color: white;
  margin-top: 20px;
}
.propositions p {
  font-size: 20px;
  font-weight: 700;
  color: white;
  font-size: 22px;
  width: 1000px;
}
.summarys h1 {
  margin-bottom: 50px;
  color: white;
  margin-top: 20px;
}
.summarys p {
  font-size: 20px;
  font-weight: 700;
  color: white;
  font-size: 22px;
  width: 1200px;
  margin-top: -20px;
}
.joins{
  position: relative;
  right: 335px;
  top: -30px;
}
.joins h2 {
  margin-bottom: 50px;
  color:white;
  margin-top: 20px;
  font-weight: 900;
}
.joins p {
  font-weight: 900;
  color: darkblue;
  font-size: 40px;
  font-style: italic;
}
@media screen and (max-width:600px) {
  html,body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  .state-outer,
  .objective-outer,
  .forecast-outer,
  .summary-outer,
  .history-outer
   {
    width: 100%;
    height: 570px;
  }
  .statements,
  .forecasts,
  .historys,
  .objectives {
    width: 100%px;
    height: 200px;
    margin-left:-80px;
  }
  .position-outer {
    width: 100%;
    height: 350px;
  }
  .statements h1,.historys h1,.forecasts h1,.summarys h1,.objectives h1,.propositions h1{
    margin-left: 2px;
  }
  .join-outer{
    margin-left: -50px;
    width: 100%;
  }
  .joins{
    left:50px;
  }
  .input-form-group label {
    width: 230px;
    margin-left: 65px;
  }
  .submit-bottom {
    left: -65px;
  }
  .alls{
    height: 195vh;
    background: linear-gradient(#000e2e,#004f5a)
  }
  .all{
    height: 162vh;
    background: linear-gradient(#7087bb,#004f5a)
  }
  .al {
    height:130vh;
    background: linear-gradient(#87878a,#02a2a2)
  }
  .als{
    height: 110vh;
  }
  .summarys p,
  .objectives p,
  .propositions p,
  .historys p,
  .forecasts p,
  .statements p {
    font-size: 20px;
    width: 100%;
  }
}
@media screen and (min-width:1920px){
  html,body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  .alls{
    height: 195vh;
  }
  .all{
    height: 70vh;
  }
  .al {
    height:75vh;
  }
  .als{
    height: 75vh;
  }
    .statements h1,.forecasts h1{
      margin-left: 4px;
     }
}