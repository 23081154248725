.nav-bar {
  background:#000e2e;
  height: 108px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 999;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
}
.menu-bar {
  position: relative;
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  color: whitesmoke;
  right: -185px;
}
.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  color: whitesmoke;
  display: none;
}
.nav-menu {
  background:#000e2e;
  position:fixed;
  width: 260px;
  height: 100%;
  justify-content: center;
  align-items: center;
  top: 0;
  left: -100%;
  transition: 950ms;
  z-index: 999;
  
}
.nav-menu.active {
  left: 0;
  transition: 950ms;
}
.nav-text{
  list-style: none;
  line-height: 60px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1)
}
.nav-text a {
  position: relative;
  color: white;
  text-decoration: none;
  font-size: 18px;
  padding-left: 10px;
  font-weight: 600;
  display: block;
  width: 100%;
  border-left:3px solid transparent;
}
span{
  width: 100px;
  margin-left: -5px;
} 
.nav-text a:hover {
  color: cyan;
  background:#1b1b1b;
  border-left-color:cyan ;
  cursor: pointer;
}
.nav-menu-items {
  width: 100%;
}
.toggle {
  color: red;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: start;
  align-items: center;
}
span {
  width: 100%;
}
.fa-times {
  color: aliceblue;
}
.fa-bars {
  color: azure;
}
.navbar-links {
  display: flex;
  justify-content: flex-end;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}
.navbar-links li {
  list-style: none;
  padding: 15px;
  margin-left: -80px;
}
.navbar-links a {
  text-decoration: none;
  color: whitesmoke;
  padding: 0px 14px;
  width: 20px;
  font-weight: 700;
  padding: 20px;
  margin: 1px;
}
.nav-logo {
  display: flex;
  margin-right: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.nav-logo a {
  text-decoration: none;
  color: whitesmoke;
}
.logo {
  font-size: 1.9rem;
  font-weight: 800;
  margin-left: 30px;
  white-space: nowrap;
  margin-top: -12px;
}
.navbar-links ul li a:hover {
  background-color: aqua;
  border-radius: 4px;
  cursor: pointer;
}
.btn {
  background-color:rgb(202, 3, 3);
  color: white;
  text-decoration: none;
  border: 2px solid transparent;
  font-weight: bold;
  padding: 10px 25px;
  border-radius: 30px;
  transition: 0.5s;
  border: 1px solid white;
  margin-left: -5px;
}
.btn:hover {
  transform: scale(1.1);
}
.profiles {
  margin-top: 15px;
  height: 100px;
  width: 100px;
  border-radius: 50px;
}
@media screen and (max-width:1200px){
  html,body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  .navbar-links {
    display: none;
  }
  .menu-bars {
    display: flex;
    left: -10px;
    margin-top: -7px;
  }
  .logo {
    margin-top: -10px;
  }
}

@media screen and (max-width:600px) {
  html,body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
 .nav-bar{
  width: 100%;
 }
  .navbar-links {
    display: none;
  }
  .nav-text {
    height: 52px;
  }
  .menu-bars {
    margin-left: 5px;
    display: flex;
    margin-top: 10px;
  }
  .logo {
    margin-top: 9px;
    margin-left: -35px;
    font-size: 19px;
  }
  .profiles{
    display: none;
  }
  .box{
    display: none;
  }
}
